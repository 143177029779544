.types {
    display: flex;
    flex-direction: column;
    margin: 48px 0px;
}

.betTypes {
    color: var(--gray);
    border-top: 2px solid var(--gray);
    border-bottom: 2px solid var(--gray);
    padding: 8px;
}

.betTypesContainer {
    background-color: var(--lightGreen);
    margin: 8px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.types p {
    font-weight: bold;
    letter-spacing: 0.05rem;
    color: var(--gray);
    margin: 0;
}



@media (max-width:575px) {
    .types p {
        text-align: center;
    }
}