.profileFieldsWrapper {
    padding: 32px 0px;
}

.inputLabel {
    color: var(--black);
    font-weight: bold;
}

.input {
    border-radius: 0px;
    height: 50px
}

.editSubmitButton {
    width: 100% !important;
    background-color: var(--lightGreen) !important;
    border: none !important;
    padding: 12px !important;
    border-radius: 0px !important;
    font-weight: bold !important;
    color: var(--gray) !important;
}