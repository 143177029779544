.footerWrapper {
    background-color: var(--lightGreen);
    height: auto;
}

.linksContainer {
    color: var(--gray);
    margin-top: 32px;
    margin-left: 16px;
    font-weight: 600;
}

.socialMediaWrapper {
    text-align: left;
}

.socialMediaContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray);
    background-color: var(--lightGreen);
    height: 40px;
    font-weight: 600;

}

.footerText {
    font-size: 14px;
    text-align: left;
    margin-top: 8px
}

.SocialMediaIcons {
    margin-right: 16px;
    cursor: pointer
}

@media screen and (max-width:767.5px) {
    .socialMediaWrapper {
        text-align: center !important;
    }

    .footerText {
        text-align: center;
        margin-top: 16px;
    }
}

.footerLinkTitle {
    color: var(--gray);
    font-weight: bold;
    text-transform: capitalize;
}

.footerLinks {
    color: var(--gray);
    cursor: pointer;
}