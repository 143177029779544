.historiesContainer {
    padding: 16px;
    height: 400px;
    width: 100%;
    overflow-y: auto;
}

.historyTableHead {
    background-color: var(--lightGreen);
    color: var(--gray);
}

.historiesContainer::-webkit-scrollbar {
    display: none;
}

.historiesTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
}

.historiesInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}