.contactUsWrapper {
    padding: 16px;
}

.contactButton,
.contactButton:hover,
.contactButton:focus {
    width: 100%;
    background-color: var(--lightGreen);
    color: var(--gray);
    font-weight: bold;
    border-radius: 0px;
    border: none;
}