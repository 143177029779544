.gameTableContainer {
     height: 50vh;
     overflow-y: auto;
     padding: 16px 0px;
     background-color: var(--blue);
}

.gameTableContainer::-webkit-scrollbar {
     display: none;
}

.tableSportsTitle {
     color: var(--gray);
     border-top: 2px solid var(--gray);
     border-bottom: 2px solid var(--gray);
     padding: 8px;
}

.tableHead {
     color: var(--white);
}

.tableRow {
     color: var(--white);
}

.tableRow td {
     padding: 16px 0px;
}