.title {
    color: var(--gray);
    border-top: 1.5px solid var(--gray);
    border-bottom: 1.5px solid var(--gray);
    width: 100%;
    padding: 8px;
}

.date {
    background-color: none;
    height: 40px;
    width: 100%;
    border: 1px solid #c4c4c4;
    padding-left: 8px;
    cursor: pointer
}

.PhoneInput {
    width: 100%;
    border: 1px solid #c4c4c4;
    padding: 8px;
    border-radius: 4px;
}

.modal-content {
    border-radius: 0px;
}

.modal-header {
    background-color: var(--lightGray);
    color: white;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-body {
    padding: 0px !important;
}

.PhoneInputInput {
    border: none;
    color: #000000;
}

.textInput {
    margin-bottom: 24px;
}

.ModalFooterContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.ModalFooterSubmit {
    width: 100%;
    background-color: var(--lightGreen);
    border: none;
    padding: 8px;
    border-radius: 4px;
    font-weight: bold;
    color: var(--gray);
    margin-bottom: 24px
}

.ModalFooterSubmit:hover {
    background-color: var(--lightGreen);
}

.ModalFooterSubmit:focus {
    background-color: var(--lightGreen);
}

.signupFormsContainer {
    display: flex;
    margin-bottom: 24px;
    margin-top: 16px;
}

.warningBody {
    padding: 16px;
}

.warningButton,
.warningButton:focus,
.warningButton:hover {
    background-color: var(--lightGreen);
    color: var(--gray);
    font-weight: bold;
    border: none;
    border-radius: 0px;
}