.newsWrapper {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    text-align: center;
    flex-direction: row;
}

.newsContainer {
    width: 270px;
    height: 400px;
    margin: 8px;
    background-color: var(--gray);
}

.newsWrapper::-webkit-scrollbar {
    display: none;
}

.NewsBigTitle {
    color: var(--gray);
    border-top: 2px solid var(--gray);
    border-bottom: 2px solid var(--gray);
    padding: 8px;
}

.newsTitle {
    padding: 16px 8px;
    height: 138px !important;
    font-size: 18px !important;
    line-height: 1.3px;
    color: var(--lightGreen);
    font-weight: 600;
    text-align: left;
}

.imageDateCatContainer {
    position: relative;
    width: 270px;
    height: 200px;
}

.dateCatContainer {
    background-color: var(--gray);
    width: 70%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 1000;
    padding: 4px;
}

.newsDate {
    font-weight: 600;
    color: var(--lightGreen);
    font-size: 12px;
    line-height: 1.3;
    margin-bottom: 0;
    text-align: left;
}

.newsCat {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.3;
    color: var(--lightGreen);
    text-align: left;
    margin-bottom: 0;
}

.newsImage {
    width: 270px;
    height: 200px;
    aspect-ratio: 1/1;
    position: relative;
}

.readMoreButtonContainer {
    padding: 0px 8px;
}

.readMoreButton {
    padding: 8px 0px;
    width: 100%;
    background-color: var(--lightGray) !important;
    border: none !important;
    border-radius: 0px !important;
}

.readMoreButton a {
    text-decoration: none !important;
    color: var(--gray);
    font-weight: bold;
}