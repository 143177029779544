.reviewsWrapper {
    margin: 48px 0px;
}

.reviewsTitle {
    color: var(--gray);
    border-top: 2px solid var(--gray);
    border-bottom: 2px solid var(--gray);
    padding: 8px;
}

.reviewsContainer {
    width: 100%;
    display: flex !important;
    flex-direction: row;
    overflow-x: scroll;
}

.reviewsContainer::-webkit-scrollbar {
    display: none;
}

.reviewContainer {
    margin: 16px;
    padding: 16px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #c9d9e7;
}

.reviewComment {
    height: 100px;
}

.reviewNameContainer {
    display: flex;
    border-bottom: 2px solid var(--gray);
    margin-bottom: 8px;

}

.reviewName {
    font-weight: bold;
    padding-left: 8px;
}

.checked {
    color: orange;
    margin-right: 8px;
}