.widthDrawWrapper {
    padding: 16px;
    width: 100%;
}

.myWalletContainer p {
    font-size: 16px;
    font-weight: 600;
}

.toWalletContainer p,
.withdrawAmountContainer p {
    font-size: 14px;
    font-weight: 600;
}

.withdrawTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
}

.toWalletContainer,
.withdrawAmountContainer {
    margin-top: 12px;
}

.withdrawCheckboxContainer {
    margin-top: 8px;
}

.withdrawSendContainer {
    margin-top: 12px;
}

.withdrawSubmitButton {
    border-radius: 0px !important;
    background-color: var(--lightGreen) !important;
    padding: 12px 0px;
    color: var(--gray) !important;
    font-weight: bold;
    border: none;
    width: 100%;
}

@media (max-width:1300px) {
    .widthDrawWrapper {
        height: auto;
    }

    .withdrawSendContainer {
        margin-top: 8px;
    }
}

.successMessageContainer {
    width: 100%;
    padding: 8px 0px;
}

.successMessageContainer p {
    padding: 8px;
    color: #228B22;
    font-size: 18px;
    font-weight: 600;
}

.errorMessageContainer {
    width: 100%;
}

.errorMessageContainer p {
    padding: 8px;
    color: red;
    font-size: 18px;
    font-weight: 600;
}

.maxButton,
.maxButton:hover,
.maxButton:focus {
    background-color: var(--lightGray);
    border: none;
}