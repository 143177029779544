.carouselItem {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 85vh;
    width: 100%;
}

.carouselContainer {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:650px) {
    .carouselItem {
        height: 50vh;
    }
}

@media screen and (max-width:400px) {
    .carouselItem {
        height: 30vh;
    }
}